@import 'src/styles/variables.scss';

.auto-accept-intenet-bids-toast {
  position: fixed;
  top: 10px;
  padding: 20px;
  color: white;
  font-family: $base-font-family;
  text-align: center;
  background: rgba($color: black, $alpha: 80%);
  border-radius: 6px;
  transform: translate(-50%, 0);

  .close-btn {
    position: absolute;
    top: -12px;
    right: -12px;
    display: block;
    width: 30px;
    height: 30px;
    background: url(#{$public-images-path}close-btn.png);
    border: 0;
    cursor: pointer;
  }

  strong {
    display: block;
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 20px;

    span {
      &.text-green {
        color: $irish-green;
      }

      &.text-red {
        color: $red-brown;
      }
    }
  }

  p {
    margin: 0;
    font-size: 14px;
  }
}
